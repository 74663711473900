import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { css } from "@emotion/core"

import { FaBoxOpen } from "react-icons/fa"

const ProductCard = (props) => {
  const {
      product,
      details
    } = props

  // console.log(props)

  return (
    <div css={card}>
      <Link to={`/prestation/${product.yamlId}`}>
        <div>
          {product.thumb && 
            <div css={imgWrapper}>
              <GatsbyImage image={product.thumb.childImageSharp.gatsbyImageData} alt={`${product.name} en Suisse romande`} />

              <div style={{"position": "absolute", "bottom": "0", "left": "20px"}}>
                <div className="card__categories">
                    {product.categories && product.categories.map( category => (
                      <div key={category}>#{category}</div>
                    ))}
                </div>
              </div>
            </div>
          }
          <div css={cardContainer}>
            <h2>{product.name} en Suisse</h2>
          </div>
          {details && 
            <div>
              <div css={[cardContainer]}>
                <p><span>BESOIN</span><br/> {product.goal}</p>
              </div>
              <div css={[cardContainer, withBorderTop]}>
                <p><span><FaBoxOpen style={{marginBottom: '-2px'}} /> LIVRABLES</span><br/> {product.deliverables}</p>
              </div>
            </div>
          }
        </div>
      </Link>  
    </div>
  )
}

export default ProductCard


const card = css`
  border-radius: 10px;
  box-shadow: 0 0 20px #e4dfdf;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  transition: transform .3s ease-in-out;

  a {
    text-decoration: none;
    color: inherit;
  }

  p { 
    margin: 0;
    font-size: 14px;
    line-height: 1.4;
  }

  span {
    text-transform: uppercase;
    opacity: .2;
    font-size: 14px;
    font-weight: 700;
  }

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
`

const imgWrapper = css`
  position: relative;
`

// const cardLink = css`
//   margin-top: 0;
//   display: block;
//   padding: 10px 20px;
//   border-top: 1px solid #EBEBEB;
//   border-radius: 0 0 5px 5px;
//   text-align: center;
//   text-decoration: none;
//   color: black;
//   transition: background-color, color .4s ease-in-out;
//   font-family: 'Courier Prime', monospace;
//   font-weight: 700;
//   color: #8C8C8C;
//   font-size: 15px;
  
//   :hover {
//     background-color: #fadc00;
//     color: black;
//   }
// `

const cardContainer = css`
  margin: 10px 0;
  padding: 0 20px;

  h2 {
    padding-top: 3px;
    margin: 0;
    font-size: 18px;
    line-height: 1.2;
  }
`

const withBorderTop = css`
  border-top: 1px solid #EBEBEB;
  padding-top: 10px;
`