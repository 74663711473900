import React from "react"
import { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { css } from "@emotion/core"
import { colors } from "../styles/constants"
import { MdClose, MdKeyboardArrowRight } from "react-icons/md"
import { GatsbyImage } from "gatsby-plugin-image"


const Promo = () => {
    const data = useStaticQuery(graphql`
        query AllPromos {
            promos: allAirtable(
                filter: {table: {eq: "Promos"}, data: {Name: {ne: null}}}
                limit: 2
                sort: {fields: data___Expiration, order: ASC}
            ) {
              edges {
                node {
                  data {
                    Name
                    Description
                    Limit
                    Expiration(fromNow: true, locale: "fr-fr")
                    Price
                    Rabais
                  }
                }
              }
            }
            image_looking: file(relativePath: {eq: "digital-box--looking.png"}) {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    height: 166
                    placeholder: BLURRED
                  )
                }
            }
          }
    `)

    const promos = data.promos.edges.map( edge => edge.node.data )

    const [open, setOpen] = useState( 10 )

    return (
        <div css={main}>
            <div css={intro}>
                <div css={sticker}>
                    <GatsbyImage image={data.image_looking.childImageSharp.gatsbyImageData} alt="A la recherche des promos"/>
                </div>
                <h4 className="callout dark">#FlashSale</h4>
                <h2>C'est votre jour de chance !</h2>
                <p>Chaque mois à l’occasion de nos ventes flash, nous mettons à disposition des coupons de réduction de 10 à 30%, pour une durée limitée. Profitez-en maintenant !</p>
            </div>
            <div css={grid}>
                {promos.map( (promo, index) => (
                    <div key={promo.Name} css={index === 0 ? card : [card, cardAlternate]}>
                        <div 
                            css={index === 0 ? cardMain : [cardMain, cardMainAlternate]}
                            onClick={() => setOpen(index)}
                            aria-label="Ouvrir promotion"
                        >
                            <h3>{promo.Name}</h3>
                            <p>{promo.Description}</p>
                            <div>
                                <MdKeyboardArrowRight />
                            </div>
                        </div>

                        <div css={index === 0 ? cardMeta : [cardMeta, cardMetaAlternate]}>
                            <div>
                                <h6>Places Limitées</h6>
                                <span>{promo.Limit} coupons</span>
                            </div>
                            <div>
                                <h6>L'offre expire</h6>
                                <span>{promo.Expiration}</span>
                            </div>
                            <div>
                                <h6>Promo</h6>
                                <span className="big">- {promo.Rabais * 100} %</span>
                            </div>
                        </div>

                        <div css={index === 0 ? overlay : [overlay, overlayAlternate]} style={{"display": index !== open ? "none" : "flex"}}>
                            <form name={`promo-${promo.Name}`} data-netlify="true" method="post" netlify-honeypot="bot-field" action="/merci" css={form}>
                                <input id="email" type="email" name="email" aria-label="email" placeholder="email@entreprise.ch" required />
                                
                                <input type="hidden" name="promo-rabais" value={`-${promo.Rabais * 100}%`} />
                                <input type="hidden" name="promo-expiration" value={promo.Expiration} />
                                <input type="hidden" name="promo-limit" value={`${promo.Limit} coupons`} />

                                <input type="hidden" name="bot-field" aria-label="bot field" />
                                <input type="hidden" name="form-name"  aria-label="form name" value={`promo-${promo.Name}`} />
                                
                                <button  type="submit" aria-label="Envoyer">Demander l'offre</button>
                            </form>

                            <div css={gdpr}>En soumettant ce formulaire, j'accepte la <a href="https://www.lyketil.com/politique-confidentialite">politique de confidentialité des données</a>.</div>
                            <button css={closebtn} onClick={() => setOpen(null)} aria-label="Fermer la promo"><MdClose /></button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Promo

const main = css`
    margin-top: 20px;
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    position: relative;
`

const intro = css`
    text-align: center;
    max-width: 700px;
    margin: 0 auto 20px;

    h2 {
        margin: 10px 0 5px;
    }

    p {
        margin: 0;
        font-size: 16px;
    }
`

const sticker = css`
     position: absolute;
     top: -10px;
     left: 20px;
     rotate: 10deg;

     @media screen and (max-width: 800px) {
        width: 100px;
        top: -60px;
     }
`

const grid = css`
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    
    @media screen and (min-width: 800px) {
        grid-template-columns: 1fr 1fr;
    }
`

const card = css`
    background-color: ${colors.accent1_pale};
    border: 4px solid ${colors.accent1}; 
    border-left: 16px solid ${colors.accent1};
    box-shadow: 4px 4px 0px ${colors.accent1_pale};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
`

const cardAlternate = css`
    background-color: ${colors.accent2_pale};
    border: 4px solid ${colors.accent2}; 
    border-left: 16px solid ${colors.accent2};
    box-shadow: 4px 4px 0px ${colors.accent2_pale};
`

const cardMain = css`
    position: relative;
    cursor: pointer;

    h3 {
        border-bottom: 4px solid ${colors.accent1};
        margin: 0;
        line-height: 1;
        padding: 14px 10px 10px;
    }

    p {
        padding: 10px;
        margin: 0;
        font-size: 14px;
    }

    div {
        position: absolute;
        top: 5px;
        right: 0px;

        svg {
            font-size: 40px;
        }
    }
`

const cardMainAlternate = css`
    h3 {
        border-bottom: 4px solid ${colors.accent2};
    }
`

const cardMeta = css`
    background-color: ${colors.accent1};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4px;
    border-top: 4px solid ${colors.accent1};

    div {
        padding: 10px;
        text-align: center;
        background-color: ${colors.accent1_pale};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h6 {
            margin: 0 0 10px;
            font-family: "Open Sans", sans-serif;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
        }

        span {
            font-size: 14px;
            font-family: 'Courier Prime', monospace;
            line-height: 1;

            &.big {
                font-size: 20px;
                font-weight: 700;
            }
        }
    }
`

const cardMetaAlternate = css`
    background-color: ${colors.accent2};
    border-top: 4px solid ${colors.accent2};

    div {
        background-color: ${colors.accent2_pale};
    }
`

const overlay = css`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${colors.accent1};
`

const overlayAlternate = css`
    background-color: ${colors.accent2};
`

const form = css`
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.black};
    width: 90%;
    border-radius: 10px;
    overflow: hidden;

    input {
        display: block;
        margin: 0;
        border: none;
        background-color: rgba(255, 255, 255, .2);
        width: 100%;
        text-align: center;
        padding: 10px 20px;
        font-family: 'Courier Prime', monospace;
        color: ${colors.black};

        ::placeholder {
            opacity: .88;
        }

        :focus {
            border: none;
            outline: none;
            background-color: rgba(255, 255, 255, .66);
        }
    }

    button {
        display: block;
        margin: 0;
        width: 100%;
        border: none;
        background-color: ${colors.black};
        color: white;
        padding: 10px 30px;
        cursor: pointer;
        font-family: 'Courier Prime', monospace;
        transition: padding .3s ease-in-out;

        :hover {
            padding-left: 40px;
        }
    }
`

const gdpr = css`
    color: ${colors.black};
    font-size: 12px;
    text-align: center;
    line-height: 1.2;
    max-width: 300px;
    margin-top: 10px;

    a {
        color: ${colors.black};
    }
`

const closebtn = css`
    border: none;
    background: none;
    padding: 0;
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    // z-index: 10;
`