import React from 'react'
import { Link } from 'gatsby'

import { css } from "@emotion/core"

const Solution = (props) => {
    const {
        id,
        name,
        categories,
        description, 
        link
    } = props

    return (
        <div key={id} css={main}>
            <Link to={`/solution/${link}`}>
                <h3>{name}</h3>
                {description && <p>{description}</p>}

                {false && categories && 
                    <div className="card__categories">
                        {categories.map( category => (
                            <div key={category}>#{category}</div>
                        ))}
                    </div>
                }
            </Link>
        </div>
    )
}

export default Solution

const main = css`
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .18);
    transition: transform .3s ease-in-out;

    a {
        text-decoration: none;
        color: inherit;
    }

    h3 {
        font-size: 18px;
        margin-bottom: 0px;
    }

    p {
        margin: 0;
        font-size: 14px;
        line-height: 1.3;
    }

    &:hover {
        transform: scale(1.02);
        cursor: pointer;
    }
`