import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductCard from "../components/productCard"
import Solution from "../components/solution"
import Button from "../components/button"
import Promo from "../components/promo"
import RecruitmentBox from "../components/recruitmentBox"

import { MdSearch } from "react-icons/md"

import { css } from "@emotion/core"
import Nocode from "../components/nocode"
// import { colors } from "../styles/constants"

const IndexPage = (props) => {
  const products = props.data.services.edges
  const solutions = props.data.solutions.edges
  const tools = props.data.tools.edges

  const [filter, setFilter] = useState( "" )
  const [filteredProducts, setFilteredProducts] = useState( products )

  const onFilterChange = (filter) => {
    // each time filter changes (from the input)
    // set the filter state to the new value
    setFilter(filter)
    // only select products that have the {filter} word in their [name]
    const filterSelect = products.filter( item => [item.node.name, item.node.goal, item.node.deliverables].join(" ").toLowerCase().includes(filter.toLowerCase()) )
    // then populate the result to the filteredProducts state
    setFilteredProducts(filterSelect)
  }

  return (
    <Layout>
      <Seo title="Digital-Box.ch | Le top des services digitaux et du NoCode en Suisse romande" />
  
      <div css={heroSection} className="bg--beige">
        <div className="container">
          <h1>Tous les services digitaux pour votre PME en Suisse romande.</h1>
          <h3>La boite à outils multimedia et No-Code du digital romand.</h3>

          <div css={personaCases}>
            <div className="hero__persona--demand">
              <h3><span className="stabilo alt2">Vous cherchez</span> des services digitaux</h3>
              <p>Vous êtes une PME ou ONG basée en Suisse et avez des besoins digitaux ? Vous trouverez ici de nombreux services nécessaires à votre digitalisation, fournis par des talents locaux <span role="img" aria-label="drapeau suisse">🇨🇭</span></p>
              <div css={heroCTAs}>
                <Button url="#services" text="Services" />
                <Button url="#solutions" text="Solutions Complètes" alt2 />
              </div>
            </div>

            <div className="hero__persona--supply">
              <h3><span className="stabilo alt4">Vous proposez</span> des prestations digitales ?</h3>
              <p>Vous êtes une agence ou un.e freelance dans le domaine du digital en Suisse romande ? Si vos prestations correspondent, inscrivez-vous pour recevoir des appels d'offre !</p>
              <div css={heroCTAs}>
                <div className="title--small introductor">Je suis un.e</div>
                <Button url="/agences" text="Agence" alt3 />
                <Button url="/freelances" text="Freelance" alt4 />
              </div>
            </div>
          </div>

          <Promo />
        </div>
      </div>

      <div className="container" css={searchEngine}>
        <input 
          type="text" name="filter" aria-label='Moteur de recherche de prestation digitale'
          value={filter} 
          placeholder="Cherchez une prestation"
          onChange={(e) => onFilterChange(e.target.value)}
        />
        <div className="search-icon"><MdSearch /></div>
      </div>

      <div style={{
        paddingTop: '50px',
      }}>
        <div className="container">
          <h2>{filteredProducts.length} services <span className="stabilo">digitaux et multimédia</span> en Suisse romande</h2>
        </div>
        <div css={productsGrid} id="services">
          {filteredProducts.map( ( {node: product} ) => (
            <ProductCard product={product} key={product.yamlId} />
          ))}
        </div>
      </div>

      <div style={{
        padding: '80px 0 50px',
        margin: '50px 0 0',
      }} className="bg--color1">
        <div className="container">
          <div css={sideImg}>
            <div className="side-img">
              <GatsbyImage image={props.data.image_happy.childImageSharp.gatsbyImageData} alt="Solutions NoCode 100% satisfait"/>
            </div>
            <div>
              <h2>Solutions digitales #NoCode clés-en-main</h2>
              <p>Demandez une solution clé en main, prête à l'utilisation par votre équipe. Nous choisissons les solutions techniques No Code ou Low Code adaptées pour fournir une solution rapidement et à bas coûts. Vous pourrez ensuite faire évoluer cette solution pour suivre votre croissance et vos besoins.</p>
            </div>
          </div>
        </div>

        <div css={productsGrid} id="solutions">
            {solutions.map( ( {node: solution} ) => (
              <Solution 
                key={solution.id}
                id={solution.id}
                name={solution.data.Name}
                description={solution.data.Description}
                categories={solution.data.Categories}
                link={solution.data.Slug}
              />
            ))}
        </div>
      </div>

      <div style={{
        padding: '80px 0 50px',
        textAlign: 'center',
      }} className="bg--color3">
        <div className="container">
          <h4 className="callout dark">Toolbox</h4>
          <h2>Le top des <bold>outils No-Code</bold> pour votre digital</h2>
          <p>Le stack digital (les outils à dispositions) n'ont jamais été aussi complets, puissants et connectés. Plutôt que de développer des solutions lentes et chères, nous vous proposons de travailler avec les outils NoCode/LowCode de référence.</p>
          
          <Nocode />
        </div>

        <div css={toolsGrid}>
          {tools.map( ({ node: tool }) => (
            <div><GatsbyImage image={tool.data.Logo.localFiles[0].childImageSharp.gatsbyImageData} alt={`${tool.data.Name} outil no-code`} /></div>
          ))}
        </div>
      </div>

      <div style={{
        marginTop: '120px',
      }}>
        <div className="container">
          <RecruitmentBox 
            climate
            title="Vous êtes une ONG / association / entreprise engagée dans la transition écologique et la justice sociale et climatique ?"
            paragraph="Digital Box s'engage à vos côtés pour vous aider dans votre mission, en vous soutenant par des mesures supplémentaires. Nous souhaitons rendre plus accessibles les compétences et talents digitaux pour les projets qui rendent la Suisse plus résiliente et lui permettent d'avancer plus vite dans la transition sociétale qui marque notre siècle."
          />
        </div>
      </div>

      <div style={{
        paddingTop: '100px',
      }}>
        <div className="container" css={pitchSection}>
          <h2><span className="stabilo alt4">Le top du NoCode</span> à Lausanne, Genève, Sion, Neuchâtel, Berne, Fribourg, Delémont</h2>
          <p>Les besoins digitaux sont en {new Date().getFullYear()} largement partagés par les <b>petites et moyennes entreprises de Suisse romande qui souhaitent "se digitaliser"</b>. Qu'on soit une entreprise à Lausanne, Genève, Sion, Neuchâtel, Berne, Fribourg, Delémont... le digital représente un axe de croissance indéniable pour développer son activité par le web.</p>

          <h4 className="title--small">Le NoCode arrive en Suisse romande</h4>
          <h3>Développement web, web-app et applicatif en <span className="stabilo al1">No-Code</span> en Suisse romande</h3>
          <p>La tendance du No-Code et du Low-Code est en pleine explosion depuis 5 ans. Très utilisée dans les startups digitales, elle devient connue et disponible des PMEs.</p>
          <p>En éliminant le besoin de connaissances avancées en codage pour créer des sites Web et des applications, il est devenu plus facile que jamais de développer des produits numériques avec le No Code. Cela est rendu possible par des plates-formes low-code telles que Webflow et Glide qui permettent aux utilisateurs de créer facilement des sites Web et des applications mobiles entièrement fonctionnels. Il offre une opportunité unique aux entrepreneurs, aux développeurs, aux petites entreprises et même aux particuliers de produire rapidement leurs propres projets sans avoir besoin de compétences en programmation traditionnelles.</p>
          
          <h4 className="title--small">Pourquoi le No-Code et Low-Code en Suisse romande ?</h4>
          <h3>Nos <span className="stabilo alt2">compétences</span> digitales en Suisse romande</h3>
          <p>Les <b>plates-formes no-code et low-code</b> ont révolutionné la façon dont nous créons des sites Web et des applications. Ils deviennent de plus en plus populaires car ils permettent aux utilisateurs sans connaissances techniques de créer des pages Web, des applications et des produits numériques simplement et rapidement, pour des coûts réduits. Les plates-formes #NoCode telles que Webflow ou Glide offrent une interface utilisateur intuitive et facile à utiliser, ce qui simplifie le développement de produits numériques. Elles <b>enlèvent aussi les coûts (et stress) de maintenance</b> et de gestion de plugins ou failles de sécurité par faute de mises à jour.</p>
          <p>En plus de permettre aux utilisateurs non techniques de créer des applications, les environnements no-code/low-code peuvent également être extrêmement utiles pour les entreprises qui ont besoin de lancer rapidement un site Web ou une application. Avec un <b>investissement de temps et budget minimal</b>, les entreprises, institutions, et startups peuvent tirer parti de ces plateformes pour <b>créer des projets digitaux plus rapidement que jamais, en quelques jours/semaines plutôt que mois</b>. Cela permet aux équipes de collaborer à la création de sites Web même si les membres ont différents niveaux d'expertise en matière de codage - la plateforme fait le plus gros du travail "technique" pour vous !</p>
          
          <h4 className="title--small">Compétences créatives et multimédia pour vous accompagner</h4>
          <h3>Votre partenaire <span className="stabilo alt4">multimédia créatif</span> en Suisse romande</h3>
          <p>Sans contenu multimédia, le digital ne va pas bien loin. Notre pôle créatif en Suisse romande vous accompagne dans la réalisation de productions de contenu vidéo et photo, à la pointe des pratiques actuelles, vous permettant ainsi d'avoir tout le contenu nécessaire pour la promotion de votre activité sur le web et print.</p>
          <p>Notre équipe suisse romande s'occupe de vos vidéos, live streaming, animation, illustration, rendering 3D pour le web, images par AI (intelligene artificielle), direction artistique, webinars, contenus pour les réseaux sociaux, motion graphics...</p>

          <h2 style={{marginTop: '40px'}}>De <span className="stabilo alt3">quels services</span> digitaux avez-vous besoin pour votre PME ?</h2>
          <h3>Faire connaître son entreprise sur internet en Suisse romande</h3>
          <p>La première étape pour que votre présence digitale soit utile au développement de votre activité (qu'elle soit tournée ou non vers le profit économique) est de <b>vous faire connaître sur internet en romandie</b>. Se faire connaître c'est à la fois aller se montrer de manière pro-active auprès des audiences importantes pour vous en Suisse, mais cela veut aussi dire <b>être trouvé sur internet en Suisse romande</b> lorsqu'on vous cherche. Pour ce faire vous allez mettre en place des tactiques dites de "notoriété".</p>
          <p>Si tel est votre objectif principal, nous vous recommandons de vous faire accompagner par des professionnels du digital sur les types de mandats suivants : référencement web (SEO) local en Suisse romande, campagne d'acquisition en ligne (publicité Google Ads, Facebook Ads, Instagram Ads, LinkedIn Ads), ou des campagnes d'influenceurs en Suisse (importance des influenceurs locaux en romandie plutôt que des influenceurs ayant des audiences mondiales)...</p>
          <h3>Convaincre votre audience web de faire confiance à votre entreprise</h3>
          <p>La seconde étape pour améliorer votre présence digitale - une fois que vous arrivez à générer des visites "qualifiées" (audience réellement intéressée et localisée en Suisse romande) sur votre site internet ou sur vos plateformes (réseaux sociaux) - sera de <b>vous assurer que vous êtes 100% convainquant.e.s</b> dans votre pitch marketing et présentation d'entreprise. En effet, il ne sert à rien d'être vue. ou trouvé.e, si par la suite vous faites une impression "moyenne", et que votre visiteur.ice préfère votre concurrent.e.</p>
          <p>Sur cette <b>étape de "conviction" ou "considération"</b>, nous vous recommandons donc de travailler spécifiquement sur vos contenus digitaux afin d'être sûrs que vous vous présentez sous votre meilleur jour. Vous pouvez donc vous faire accompagner par des entreprises digitales et des freelances Suisses sur des mandats comme : la création de contenu photo ou vidéo pour votre site et réseaux sociaux, la rédaction pour le web de blogs et de pages de contenu sur votre site, l'amélioration de votre présence sur les réseaux sociaux, l'installation d'un setup de support avec un chat ou un chatbot sur votre site, la création d'une newsletter efficace et intéressante, le lancement d'une campagne publicitaire de retargeting efficace.</p>
          <h3>Pousser vos meilleurs leads à la conversion</h3>
          <p>Une fois que vous avez pu créer du trafic sur vos propriétés, et qu'une partie de ces visiteur.ice.s semble intéressée, il est important de pouvoir arriver à les <b>faire passer à l'action</b> avec le plus de facilité possible. Il peut s'agir d'une prise de contact, d'un achat, d'un téléchargement... L'important à ce stade est donc de pouvoir savoir avec précision comment faciliter l'action aux audiences qui sont les plus prêtes à passer le cap (qui sont les plus convaincues).</p>
          <p>Cette phase est particulièrement importante, car elle est le résultat visible et final de vos actions marketing depuis le "haut du tunnel" (les étapes décrites précédemment), et nous vous conseillons de vous faire accompagner par des experts digitaux en Suisse romande. Les mandats suivants font particulièrement sens pour une PME ou ONG Suisse : optimisation du taux de conversion (mandat de "CRO" - Conversion Rate Optimisation), réalisation d'une campagne de retargeting (avec généralement une stratégie promotionnelle), amélioration de votre campagne d'emailing spécifiquement pour l'étape de conversion, implémentation d'un Customer Relationship Management system (CRM) ou d'un Customer Data Platform (CDP) afin de centraliser vos audiences et permettre une meilleure segmentation des profils convertisseurs et de leur audiences similaires.</p>
          <h3>Fidéliser vos clients passés pour consolider votre relation client</h3>
          <p>Vos clients vous sont acquis s'ils/elles sont convaincu.e.s que vos produits/services/activités sont de bonne qualité. Plutôt que d'acquérir toujours plus de nouveaux clients en Suisse romande et d'acquérir de nouvelles "parts de marché", vous pouvez <b>vous concentrer sur la satisfaction d'un nombre de clients existants</b>, afin de construire une relation (marchande ou non) plus solide et vous développer sur cette base fidèle.</p>
          <p>Dans cette phase vous pouvez être accompagnés par des entreprises ou des freelances du digital en Suisse romande afin d'améliorer votre capacité à <b>fidéliser votre clientèle / audience</b>. Généralement des mandats de CRM, d'emailing, de retargeting, de politique commerciale peuvent vous aider à améliorer votre revenu et votre Customer Lifetime Value (CLV).</p>
          <h3>Activer le bouche à oreille de vos anciens clients et créer des parrainages</h3>
          <p>La dernière étape consiste à vous assurer que vos clients deviennent de fervents promoteurs de votre entreprise, et recommandent vos produits / services / activités à de nouvelles audiences. Les proches, famille, ami.e.s sont sensibles aux recommendations de leurs pairs, et il s'agit là du marketing le plus efficace qui existe pour votre entreprise. Nous vous proposons ici aussi des mandats d'accompagnement par des entreprises et freelances spécialisé.e.s dans le marketing digital, afin de vous permettre de booster vos résultats par des programmes d'affiliation, des concours viraux, des campagnes et setups de parrainage, et autres programmes de fidélisation.</p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

const heroSection = css`
  padding: 50px 0 100px;

  h1 {
    font-size: 65px;
    line-height: 1.1;
    
    @media screen and (max-width: 800px) {
      font-size: 40px;
    }
  }

  p {
    font-size: 20px;
    line-height: 1.5;
  }
`

const personaCases = css`
  // margin-top: 80px;

  > div {
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 30px #EBEBEB;
    background-color: white;
    z-index: 1;
    // margin-bottom: 20px
  }

  h3 {
    font-size: 12px;
    color: black;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 800px) {
    // margin: 0 -50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
`

const heroCTAs = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > a {
    margin-right: 20px;
  }

  .introductor {
    margin-right: 20px;
    font-family: "Open Sans", sans-serif;
  }
`

const searchEngine = css`
  margin-top: 50px;
  position: relative;

  input {    
    width: 100%;
    padding: 20px;
    border-radius: 100px;
    border: 1px solid #EBEBEB;
    font-family: 'Courier Prime', monospace;
    font-size: 20px;
    box-shadow: 0 0 20px #EBEBEB; 

    :focus, 
    :active {
      outline: none;
    }
  }

  .search-icon {
    position: absolute;
    top: 20px;
    right: 40px;
    font-size: 30px;
  }
`

const productsGrid = css`
  display: grid;
  grid-template-columns: 1fr;
  margin: 30px 30px 50px;
  gap: 20px;

  @media screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const pitchSection = css`
  font-size: 14px;
  line-height: 1.4;

  h4 {
    margin-top: 30px;
    margin-bottom: 10px;
    opacity: .33;
  }

  h2 {
    font-size: 1.4em;
  }

  h3 {
    margin-top: 10px;
    font-size: 1.2em;
  }

  p {
    margin-bottom: 20px;
  }
`

const sideImg = css`
  display: flex;
  align-items: center;

  .side-img {
    rotate: -10deg;
    flex-shrink: 0;
    margin-right: 20px;
  }

  p {
    margin: 0;
  }

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;

    .side-img {
      margin-bottom: 10px;
    }
  }
`

const toolsGrid = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px auto;
  padding: 20px;

  > div {
    border-radius: 10px;
    overflow: hidden;
  }
`

export const indexQuery = graphql`
  query indexQuery {
    services: allProductsYaml {
      edges {
        node {
          yamlId
          name
          goal
          deliverables
          categories
          thumb {
            childImageSharp {
              gatsbyImageData(
                width: 500
                quality: 88
              )
            }
          }
        }
      }
    }
    solutions: allAirtable(filter: {table: {eq: "Solutions"}, data: {Name: {ne: null}}}) {
      edges {
        node {
          id
          data {
            Slug
            Name
            Description
            Categories
          }
        }
      }
    }
    tools: allAirtable(filter: {table: {eq: "Tools"}, data: {Status: {eq: "Done"}}}) {
      totalCount
      edges {
        node {
          data {
            Name
            Logo {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    width: 100
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
    }
    image_happy: file(relativePath: {eq: "digital-box--happy.png"}) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 180
          placeholder: BLURRED
        )
      }
    }
  }
`